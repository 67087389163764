import { defineStore } from "pinia";
import {
  getUserValidation,
  addUser,
  loginUser,
  userProfile,
  getReservations,
  cancelReservation,
  putProfile,
  getNeoDollarById,
  getNeodollarBySeason
} from "@/services/dataAPi";
import { reactive, ref } from "vue";
import { DataProfile, UserValidation } from "@/types";
import { toast, type ToastOptions } from "vue3-toastify";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { useForm } from "vee-validate";

const schema = Yup.object({
  email: Yup.string()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Ingresa un email válido")
    .required("Requerido"),

  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]).{6,}$/,
      "Debe contener 6 caracteres, una mayúscula, un número y un carácter especial"
    )
    .required("Requerido"),

  username: Yup.string().required("Requerido"),

  userlastname: Yup.string().required("Requerido"),
});

export const useUserStore = defineStore("userStore", () => {
  const router = useRouter();
  const userValidationData: UserValidation = reactive({
    email: "",
    DNI: "",
    cellphone: "",
  });

  const { errors, defineField } = useForm({
    validationSchema: schema,
    initialValues: {
      email: "",
      password: "",
    },
  });

  const [email, emailProps] = defineField("email");
  const [password, passwordProps] = defineField("password");
  // const { firstname, errorMessage } = useField(() => firstname);

  const totalBooking = ref();
  const pendindBooking = ref();

  const credentials = reactive({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
  });

  const validation = ref("");

  const isLogin = ref(false);

  const dataProfile: DataProfile = reactive({
    id: 0,
    firstname: "",
    lastname: "",
    user_name: "",
    email: "",
    password: "",
    typeDNI: "",
    DNI: "",
    nationality: "",
    country: "",
    city: "",
    birthdate: "",
    created_account_date: "",
    update_account_date: "",
    address: "",
    cellphone: "",
    passportData: "",
    terms_of_service: false,
    contact_emergency_name: "",
    contact_emergency_Phone: "",
    KYC: false,
    promotions: false,
    neo_Dollars_redeemable_points: 0,
  });

  const registerUser = ref(false);
  const firstname = ref("");
  const lastname = ref("");

  const newRegister = ref(false);

  const validateUserSubmit = async (credentials: any) => {
    const userExists = await loginUser({
      user_email: credentials.email,
      password: credentials.password,
    });

    if (userExists.includes("doesn't match")) {
      toast.error("Usuario o contraseña incorrectos", {
        autoClose: 4000,
        position: toast.POSITION.TOP_RIGHT,
      } as ToastOptions);
    } else {
      toast.success("Bienvenido a Next Destiny", {
        autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
      } as ToastOptions);
      setTimeout(() => {
        router.push("/");
      }, 2000);
      localStorage.setItem("userToken", userExists);
      isLogin.value = true;
    }
  };

  const createRegisterSubmit = async (credentials: any) => {
    try {
      
      const userExists = await addUser({
        firstname: credentials.firstname,
        lastname: credentials.lastname,
        email: credentials.email,
        password: credentials.password, 
      });
  
      toast.success("Registro correcto, bienvenido a Next Destiny", {
        autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
      } as ToastOptions);
      setTimeout(() => {
        router.push("/");
      }, 2000);
      localStorage.setItem("userToken", userExists);
    } catch (error) {
      console.log(error);
      toast.error("Usuario ya registrado en el sistema", {
        autoClose: 4000,
        position: toast.POSITION.TOP_RIGHT,
      } as ToastOptions);
    }
  };

  const fetchDataUser = async () => {
    try {
      const response = await userProfile();
      Object.assign(dataProfile, response.Data);
      dataProfile.id = response.Data.id;
      return response.Data;
    } catch (error) {
      console.log(error);
    }
  };

  const userValidation = async () => {
    const response = await getUserValidation(userValidationData);
    validation.value = response.detail;
    return validation;
  };

  const fetchReservations = async (page: string) => {
    try {
      const response = await getReservations(page);
      totalBooking.value = response.reservation_data.reservation_total;
      pendindBooking.value = response.status_totals.pediend;
      return response.reservation_data.reservation;
    } catch (error) {
      console.log(error);
    }
  };

  const cancelReservationProfile = async (id: string) => {
    try {
      const response = await cancelReservation(id);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const updateProfile = async (payload: any) => {
    try {
      const response = await putProfile(payload);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  const fetchNeoDollarsById = async (id: number) => {
    try {
      const response = await getNeoDollarById(id);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  const fetchNeoDollarsBySeason = async (payload:any)=>{
    try {
      const response = await getNeodollarBySeason(payload);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  return {
    // firstname,
    errors,
    email,
    emailProps,
    password,
    passwordProps,
    userValidation,
    userValidationData,
    validation,
    credentials,
    validateUserSubmit,
    isLogin,
    registerUser,
    newRegister,
    createRegisterSubmit,
    fetchDataUser,
    dataProfile,
    fetchReservations,
    totalBooking,
    pendindBooking,
    cancelReservationProfile,
    lastname,
    firstname,
    updateProfile,
    fetchNeoDollarsById,
    fetchNeoDollarsBySeason
  };
});
