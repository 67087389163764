import axios from "axios";

import { useRoute } from "vue-router";
import { Place } from "@/types";

const api = axios.create({
  baseURL: "http://192.241.159.231:4000/",
});

api.interceptors.request.use(
  async (request) => {
    const token = localStorage.getItem("userToken");
    if (token) {
      request.headers.Authentication = `Bearer ${token}`;
    }
    return request;
  },
  (error) => {
    console.error("Error in request interceptor", error);
    return Promise.reject(error);
  }
);

const getHotels = async (payload: object) => {
  try {
    const response = await api.post(`/hotel/`, payload);
    return response.data;
  } catch (error) {
    console.error("Error fetching hotels:", error);
    throw error;
  }
};

const getHotel = async () => {
  try {
    const route = useRoute();
    const response = await api.get(`/hotel/${route.params.id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getRooms = async (payload: object) => {
  try {
    const response = await api.post(`/rooms/hotel/`, payload);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getAmenities = async () => {
  try {
    const response = await api.get(`/amenitie/`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getServices = async () => {
  try {
    const response = await api.get(`/comodities/`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getHotelServices = async (id: any) => {
  try {
    const response = await api.get(`/amenitie/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getDestinations = async (place: Place) => {
  try {
    const response = await api.get(`cities/?input_string=${place}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const addReservation = async (payload: any) => {
  try {
    const response = await api.post(`reservation/`, payload);
    window.location.href = response.data.Season_payment.checkout_url;
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const addPromotion = async (payload: any) => {
  try {
    const response = await api.post(`/promotionrelations/Validation`, payload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const loginUser = async (payload: any) => {
  try {
    const response = await api.post(`/auth/Tokenclient/`, payload);
    return response.data;
  } catch (error: any) {
    console.error(error);
    return error.response.data.detail;
  }
};

const addUser = async (payload: any) => {
  try {
    const response = await api.post(`/user/`, payload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getUserValidation = async (payload: any) => {
  try {
    const response = await api.post(`/user/verifyUser`, payload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const userProfile = async () => {
  try {
    const response = await api.get(`/user/profile`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const putProfile = async (payload: any) => {
  try {
    const response = await api.put(`/user`, payload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getReservations = async (page: string) => {
  try {
    const response = await api.get(`/reservation/get_userid/${page}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const cancelReservation = async (id: string) => {
  try {
    const response = await api.put(`/reservation/Cancel/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const postReview = async (payload: any) => {
  try {
    const response = await api.post(`/reviews/`, payload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getHotelReviews = async (id: string) => {
  try {
    const response = await api.get(`reviews/hotel/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getNeoDollarById = async (id: number) => {
  try {
    const response = await api.get(`neo_dollar/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const getNeodollarBySeason = async (payload:any) => {
  try {
    const response = await api.post(`season/get/seasonbyentity/`, payload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const getHotelById = async (id: string) => {
  try {
    const response = await api.get(`hotel/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export {
  getHotels,
  getHotel,
  getAmenities,
  getServices,
  getHotelServices,
  getDestinations,
  getRooms,
  addReservation,
  addPromotion,
  getUserValidation,
  addUser,
  loginUser,
  userProfile,
  getReservations,
  cancelReservation,
  postReview,
  getHotelReviews,
  putProfile,
  getNeoDollarById,
  getNeodollarBySeason,
  getHotelById
};

export default api;
